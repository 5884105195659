import OktaSignIn from "@okta/okta-signin-widget";
import { user, contract } from "../stores/index";
import { UsersRepository } from "../repositories";
import { navigate } from "svelte-routing";

import getFile from "./xml";
const config = getFile("/config.json");

let oktaSignIn;

const signInOptions = { scopes: ["openid", "email", "profile"] };

async function login() {
  try {
    if (!oktaSignIn) setOktaClient();

    // to make sure we don't have two okta signin
    oktaSignIn.remove();
    oktaSignIn.authClient.tokenManager.start();

    await getToken();
    await setUser();
    await redirectUser();
  } catch (e) {
    user.set(null);
    contract.set(null);
    throw e;
  }
}

async function setOktaClient() {
  oktaSignIn = new OktaSignIn({
    issuer: config.OKTA_ISSUER,
    clientId: config.OKTA_CLIENT_ID,
    redirectUri: config.REDIRECT_URI,
    services: {
      autoRenew: true,
    },
    features: {
      rememberMe: false,
      webauthn: true,
    },
    authParams: {
      devMode: "local",
    },
  });
}

async function getToken() {
  const tokens = await oktaSignIn.showSignInToGetTokens({
    ...signInOptions,
    el: "#widget-container",
  });

  // get tokens and user object
  oktaSignIn.authClient.tokenManager.setTokens(tokens);
  const token = await oktaSignIn.authClient.tokenManager.get("accessToken");

  const expirationDate = new Date(token.expiresAt * 1000);

  const tokenObj = {
    token: token.accessToken,
    type: token.tokenType,
    exp: expirationDate,
  };

  user.set({
    token: tokenObj,
  });

  return tokenObj;
}

async function setUser() {
  const result = await UsersRepository.me();

  let userStore;
  user.subscribe((data) => {
    userStore = data;
  });

  user.set({
    data: result.data,
    contracts: result.contracts,
    ...userStore,
  });
}

async function redirectUser() {
  let userStore;
  user.subscribe((data) => {
    userStore = data;
  });

  let contractStore;
  contract.subscribe((data) => {
    contractStore = data;
  });

  if (userStore.contracts.length === 0 && !userStore.data.isMaster) {
    throw new Error({
      message: "This user is not assigned to any application",
    });
  } else if (userStore.contracts.length > 1 || userStore.data.isMaster) {
    contract.set(null);
  } else {
    contract.set(userStore.contracts[0]);

    const contractApps = await UsersRepository.getUserApps(
      userStore.data.id,
      userStore.contracts[0],
    );
    const policies = await UsersRepository.getUserPolicies(userStore.data.id);
    user.set({
      ...userStore,
      apps: contractApps.list,
      policies: policies.list,
    });

    navigate(`/contracts/${contractStore.id}`);
  }
}

async function logout() {
  if (!oktaSignIn) {
    await setOktaClient();
  }

  oktaSignIn.authClient.signOut({
    postLogoutRedirectUri: config.REDIRECT_URI,
  });
  oktaSignIn.remove();

  user.set(null);
  contract.set(null);
  navigate("/");
}

const okta = {
  login,
  logout,
};

export default okta;
