import { getUserPolicies } from "../repositories/users";
import { derived, get } from "svelte/store";
class Auth {
  constructor(userStore, defaultApp) {
    this.userStore = userStore;
    this.defaultApp = defaultApp;
    this.isAuthorized = derived(userStore, ($user) => (policy, appID) => {
      if (!$user) return false;

      let appPolicies = [];

      if (appID) {
        appPolicies = $user.policies.filter((p) => p.appId === appID);
      } else if (this.defaultApp) {
        appPolicies = $user.policies.filter((p) => p.appId === this.defaultApp);
      } else if ($user.policies) {
        appPolicies = $user.policies;
      } else {
        appPolicies = [];
      }

      const hasPolicy = appPolicies.some(
        (p) => p.name === policy || (p.implicit && p.implicit.includes(policy)),
      );

      if (hasPolicy) return true;
      return false;
    });
    this.fetchPolicies = this.fetchPolicies.bind(this);
  }

  async fetchPolicies() {
    const user = get(this.userStore);

    if (user) {
      const result = await getUserPolicies(user.data.id);
      const policies = result.list;
      this.userStore.set({ ...user, policies });
    }
  }
}

export default Auth;
