import axios from "axios";
import { user, contract, callout } from "../stores/index";
import getFile from "./xml";
const config = getFile("/config.json");

// INTERCEPTING API ERRORS AND SENDING IT TO CALLOUT STORE
axios.interceptors.response.use(
  (r) => {
    return r;
  },
  (e) => {
    console.log("API ERROR data --->", e.response.data);
    console.log("API ERROR message --->", e.response.data?.message);
    if (e.request.responseURL.includes("dryrun")) return null;
    if (e?.response?.status === 403) {
      location.replace(`/forbidden`);
      throw e;
    } else if (e.config.method === "get" && e?.response?.status === 404) {
      location.replace(`/404`);
    } else {
      const errorMessage = e?.response?.data?.message
        ? `Error: ${e.response.data.message}`
        : e;
      callout.set({ msg: errorMessage, status: "error", timeout: true });
      throw e;
    }
  },
);

class API {
  constructor() {
    this.contractid = null;
    contract.subscribe((contract) => {
      this.contractid = contract ? contract.id : null;
    });
    this.url = `${config.API_URL}/access-manager/api/v1/`;
  }

  async getToken() {
    let token;
    user.subscribe((user) => {
      token = user ? user.token : null;
    });
    return `${token.type} ${token.token}`;
  }

  async request({ method, url, data, config = {} }) {
    const response = await axios.request({
      method,
      baseURL: this.url,
      url,
      data,
      ...config,
      headers: {
        Authorization: await this.getToken(),
        "gl-contract-id": this.contractid,
        ...(config.headers || {}),
      },
    });
    return response.data;
  }

  async get(url, config) {
    return await this.request({ method: "get", url, config });
  }

  async post(url, data, config) {
    return await this.request({ method: "post", url, data, config });
  }

  async put(url, data, config) {
    return await this.request({ method: "put", url, data, config });
  }

  async patch(url, data, config) {
    return await this.request({ method: "patch", url, data, config });
  }

  async delete(url, data, config) {
    return await this.request({ method: "delete", url, data, config });
  }
}

export default API;
