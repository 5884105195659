<script>
  import { onMount } from "svelte"
  import { getContext } from "svelte"

  import { user, language, contract, callout, Auth } from "./stores/index"
  const config = getContext("config")

  const authClass = new Auth(user, "content-manager")

  const load = async () =>
    await Promise.all([
      import("@gl/content-manager/src/content-manager.js"),
      import("@gl/bundescloud-player"),
    ])
</script>

{#if $contract}
  {#await load()}
    loading...
  {:then}
    <cm-element
      apiurl={config.API_URL}
      basepath={`/content-manager/contracts/${$contract.id}`}
      language={$language}
      contract={$contract}
      user={$user}
      isAuthorized={authClass}
      on:callout={(event) => callout.set(event.detail.callout)}
    />
  {/await}
{/if}
