<script>
  import { Flex, FlexItem, GL, Button } from "@gl/design-system";
  import { _ } from "../stores";
  import { IDP } from "../services/index";
  import { getContext, onMount } from "svelte";

  const config = getContext("config");

  let error = "";

  async function login() {
    try {
      await IDP.login();
    } catch (e) {
      error = e.message ?? e.statusText;
    }
  }

  onMount(() => {
    // if the IDP is okta we need to initialize it to show the login container
    if (config.OKTA_ISSUER && config.OKTA_CLIENT_ID) login();
  });
</script>

<div id="login-page" class="cubes-bg">
  <Flex class="flex-direction-column align-items-center">
    <FlexItem class="mb-2">
      <GL light />
    </FlexItem>
    <FlexItem>
      <h1 class="title">{getContext("config").NAME}</h1>
    </FlexItem>

    <FlexItem>
      <div id="widget-container" />
    </FlexItem>

    {#if config.AUTH0_DOMAIN && config.AUTH0_CLIENT_ID && config.AUTH0_AUDIENCE}
      <FlexItem class="mt-5">
        <Button on:click={login} style="width:400px">Login</Button>
      </FlexItem>

      <FlexItem class="mt-5">
        <p class="color-error gl-title level-6">{error}</p>
      </FlexItem>
    {/if}
  </Flex>
</div>
