<script>
  import { _ } from "../stores";
  import {
    Badge,
    Flex,
    FlexItem,
    Tag,
    Button,
    Loader,
  } from "@gl/design-system";
  import { format } from "date-fns";
  import { UsersRepository } from "../repositories";
  import { onMount } from "svelte";

  let loading = true;

  let user;

  async function fetchMe() {
    loading = true;
    try {
      user = await UsersRepository.me();
    } finally {
      loading = false;
    }
  }

  onMount(() => {
    fetchMe();
  });
</script>

<div class="main p-6">
  {#if loading}
    <Loader />
  {:else}
    <div class="w-100 user-main-card">
      <h2 class="gl-title user-row mb-4">{$_("users.me.profile")}</h2>

      <div class="user-row" id="user-name">
        <div class="user-title">
          {$_("common.name")}
        </div>
        <div class="user-td">
          {`${user.data.firstName} ${user.data.lastName}`}
        </div>
      </div>

      <div class="user-row" id="user-email">
        <div class="user-title">
          {$_("users.email_address")}
        </div>
        <div class="user-td">
          {user.data.email}
        </div>
      </div>

      <div class="user-row" id="user-groups">
        <div class="user-title">
          {$_("common.groups")}
        </div>
        <div class="user-td" style="display: block">
          {#if user.groups?.length > 0}
            {#each user.groups as group}
              <p class="mb-2" style="">{group}</p>
            {/each}
          {:else}
            -
          {/if}
        </div>
      </div>
      <div class="user-row" id="user-roles">
        <div class="user-title">
          {$_("common.roles")}
        </div>
        <div class="user-td" style="display: block">
          {#if user.roles?.length > 0}
            {#each user.roles as role}
              <p class="mb-2" style="">{role}</p>
            {/each}
          {:else if user.data.isMaster}
            <Badge
              class={user.roles > 0 && "mt-2"}
              status="success"
              label={$_("users.master")}
            />
          {:else}
            -
          {/if}
        </div>
      </div>
    </div>
  {/if}
</div>
